<template>
  <div>
    <v-card class="pb-3 mb-4">
      <v-card-title class="align-start">
        <span class="font-weight-semibold">Estatísticas de vales</span>
      </v-card-title>

      <v-card-subtitle class="mb-4 mt-n1">
        <span class="font-weight-semibold text--primary me-1">Informações relacionadas ao fluxo de vales</span>
      </v-card-subtitle>
      <v-row class="ml-3">
        <v-col cols="3">
          <v-autocomplete
            v-model="itemSearch"
            dense
            outlined
            :items="listRegionsItems"
            label="Filial"
          />
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="itemSearchDate"
                dense
                outlined
                label="Mês referência"
                hide-details
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="itemSearchDate"
              type="month"
              width="270"
              locale="pt-BR"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="1"
          class=""
        >
          <v-btn
            variant="tonal"
            color="info"
            @click="getItemsList(), getItemsYearly()"
          >
            <v-icon size="30">
              {{ icon.mdiSendCircleOutline }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pb-3 mb-4">
      <v-card-title class="align-start">
        <span>Visão geral</span>

        <v-spacer></v-spacer>

        <v-btn
          icon
          small
          class="mt-n2 me-n3"
        >
          <v-icon size="22">
            {{ icon.mdiDotsVertical }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="d-flex mt-5">
          <v-col
            cols="12"
            md="12"
          >
            <chartjs-component-bar-chart
              :key="labelsChart"
              :height="400"
              :data="latestBarChart.data"
              :options="latestBarChart.options"
            />
          </v-col>

          <v-spacer />
        </v-row>

        <v-row>
          <v-col
            cols="8"
            sm="12"
            class="d-flex flex-column"
          >
            <div class="d-flex align-center">
              <v-avatar
                class="v-avatar-light-bg success--text"
                rounded
                size="40"
              >
                <v-icon
                  size="30"
                  color="success"
                >
                  {{ icon.mdiCurrencyUsd }}
                </v-icon>
              </v-avatar>
              <div class="ms-4 d-flex flex-column">
                <p class="text--primary mb-0 text-base">
                  Total geral
                </p>
                <span class="text--primary font-weight-semibold text-xl">{{
                  sumItems.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }}</span>
              </div>
            </div>

            <v-divider class="my-6"></v-divider>

            <div class="row">
              <div
                v-for="item in itemsTicket"
                :key="item"
                class="col-6 col-sm-4 d-flex align-center"
              >
                <div class="circle-item"></div>
                <div class="ms-3">
                  <p class="mb-0">
                    {{ item.description }}
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    {{ item.percent }}%
                  </h3>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title class="align-start">
        <span>Visão geral</span>

        <v-spacer></v-spacer>

        <v-btn
          icon
          small
          class="mt-n2 me-n3"
        >
          <v-icon size="22">
            {{ icon.mdiDotsVertical }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <div>
        <vue-apex-charts
          :key="data"
          type="line"
          height="290"
          :options="lineChartSimple.chartOptions"
          :series="lineChartSimple.series"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import {
mdiAccountBoxMultipleOutline,
mdiAccountMultiplePlusOutline,
mdiCurrencyUsd,
mdiDotsVertical,
mdiGaugeFull,
mdiMedal,
mdiPalmTree,
mdiPassport,
mdiSendCircleOutline,
mdiShoePrint
} from '@mdi/js'
import themeConfig from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import ChartjsComponentBarChart from './charts/ChartjsComponentBarChart.vue'

export default {
  components: {
    ChartjsComponentBarChart,
    VueApexCharts,
  },
  data() {
    return {
      lineChartSimple: {},
      valuesSales: [],
      data: [],
      itemsTicketsGraphLine: '',
      itemsTicket: [],
      sumItems: '',
      latestBarChart: {
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: '#ffcf5c',
              borderColor: 'transparent',
              barThickness: 30,
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: '#836AF9',
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  stepSize: 400,
                  min: 0,
                  max: 4000,
                  fontColor: '#6e6b7b',
                },
              },
            ],
          },
        },
      },
      chartColors: {},
      lineChart: {},
      labelsChart: [],
      itemSearch: '',
      listRegionsItems: ['IMPERATRIZ', 'GRAJAÚ', 'AÇAILÂNDIA'],
      dateMenu: false,
      itemSearchDate: '',
      icon: {
        mdiSendCircleOutline,
        mdiDotsVertical,
        mdiCurrencyUsd,
        mdiGaugeFull,
        mdiShoePrint,
        mdiPalmTree,
        mdiAccountBoxMultipleOutline,
        mdiMedal,
        mdiPassport,
        mdiAccountMultiplePlusOutline,
      },
    }
  },

  created() {
    const $themeColors = themeConfig.themes.light

    const chartColors = {
      primaryColorShade: '#836AF9',
      yellowColor: '#ffe800',
      successColorShade: '#28dac6',
      warningColorShade: '#ffe802',
      warningLightColor: '#FDAC34',
      infoColorShade: '#299AFF',
      greyColor: '#4F5D70',
      blueColor: '#2c9aff',
      blueLightColor: '#84D0FF',
      greyLightColor: '#EDF1F4',
      tooltipShadow: 'rgba(0, 0, 0, 0.25)',
      lineChartPrimary: '#666ee8',
      lineChartDanger: '#ff4961',
      labelColor: '#6e6b7b',
      grid_line_color: 'rgba(200, 200, 200, 0.2)',
    }

    this.lineChart = {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: 'label',
        },
        tooltips: {
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
        },
        layout: {
          padding: {
            top: -15,
            bottom: -25,
            left: -15,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                stepSize: 100,
                min: 0,
                max: 400,
                fontColor: chartColors.labelColor,
              },
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
            },
          ],
        },
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 9,
          },
        },
      },
      data: {
        labels: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
        datasets: [
          {
            data: [856, 632, 875, 808, 856, 852, 496, 987, 654, 499, 875, 896],
            label: 'Atual',
            borderColor: chartColors.primaryColorShade,
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: chartColors.primaryColorShade,
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBackgroundColor: chartColors.primaryColorShade,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
          {
            data: [797, 708, 765, 808, 828, 816, 840, 901, 795, 784, 856, 669],
            label: 'Meta',
            borderColor: $themeColors.warning,
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: $themeColors.warning,
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBackgroundColor: $themeColors.warning,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
          {
            data: [856, 963, 699, 826, 733, 741, 968, 561, 411, 753, 951, 846],
            label: 'Ano anterior',
            borderColor: '#d4e157',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#d4e157',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBackgroundColor: '#d4e157',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
        ],
      },
    }
  },

  methods: {
    async getItemsYearly() {
      const body = {
        region: this.itemSearch,
      }
      await axiosIns.post('/api/v1/integration/vale/dash_annual', body).then(res => {
        this.data = res.data.data
      })

      this.data.map(item => {
        this.valuesSales.push(item.amount)
      })

      this.lineChartSimple = {
        series: [
          {
            data: this.valuesSales,
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 6,
            strokeOpacity: 2,
            strokeColors: ['#FFC339'],
            colors: ['#17B1FF'],
          },
          colors: ['#824EE3'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-2 py-30"><span>'}${
                data.series[data.seriesIndex][data.dataPointIndex]
              } Vale(s)</span></div>`
            },
          },
          xaxis: {
            categories: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      }
    },
    async getItemsList() {
      const body = {
        region: this.itemSearch,
        reference: this.itemSearchDate,
      }

      const labelsBarChart = []
      const valueBarChart = []

      await axiosIns.post('/api/v1/integration/vale/dashboard', body).then(res => {
        this.itemsTicket = res.data.data

        res.data.data.map(item => {
          labelsBarChart.push(item.description)
          valueBarChart.push(Number(item.value_paid))
        })
      })

      this.latestBarChart.data.labels = labelsBarChart
      this.latestBarChart.data.datasets[0].data = valueBarChart
      const sumAll = this.itemsTicket.map(item => Number(item.value_paid)).reduce((prev, curr) => prev + curr, 0)

      this.sumItems = sumAll

      this.labelsChart = labelsBarChart
    },
  },
}
</script>
<style scoped lang="scss">
.container-item {
  display: flex;
  align-items: center;
}
.circle-item {
  width: 10px;
  height: 10px;
  background: #17b1ff;
  border-radius: 16px;
  margin-right: 5px;
}

#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.container-pizza {
  width: 100%;
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
